import * as React from 'react'
import NavHeader from '../../components/navHeaderEn'
import SiteFooter from '../../components/footerEn'
import PageTitle from '../../components/pageTitle'
import { StaticImage } from 'gatsby-plugin-image'
import * as projectsStyle from '../../styles/projects.module.scss'

const Project = () => {
  return (
    <main>
    <NavHeader/>
    <PageTitle pageTitle="Projects"/>
        <div className="mainContainer">
          <div>
            <div className={projectsStyle.timeLineContainer}>
              <div className={projectsStyle.timeLine}>
                <div className={projectsStyle.timeLineStreakContainer}>
                  <div className={projectsStyle.timeLineStreakItem}>
                  </div>
                  <div className={projectsStyle.timeLineStreakItem}>
                  </div>
                  <div className={projectsStyle.timeLineStreakItem}>
                  </div>
                </div>
                <div className={projectsStyle.timeLineItemContainer}>
                  <div className={projectsStyle.timeLineItem} id={projectsStyle.triangle}></div>
                  <div className={projectsStyle.timeLineItem} id={projectsStyle.firstEn}>
                    <span>2020</span>
                  </div>
                  <div className={projectsStyle.timeLineItem} id={projectsStyle.secondEn}>
                    <span>2019</span>
                  </div>
                  <div className={projectsStyle.timeLineItem} id={projectsStyle.thirdEn}>
                    <span>2018</span>
                  </div>
                  <div className={projectsStyle.timeLineItem} id={projectsStyle.fourthEn}>
                    <span>2017</span>
                  </div>
                  <div className={projectsStyle.timeLineItem} id={projectsStyle.fifthEn}>
                    <span>2015/16</span>
                  </div>
                  <div className={projectsStyle.timeLineItem} id={projectsStyle.sixthEn}>
                    <span>2014</span>
                  </div>
                  <div className={projectsStyle.timeLineItem} id={projectsStyle.circle}></div>
                </div>
              </div>
              <div className={projectsStyle.timeLineDescription}>
                <div>
                  <div className={projectsStyle.descrtptionHeading}>
                    <span>2020</span>
                  </div>
                  <ul>
                    <li>Deployment of application optimization of CPPA with a significant increase in its performance limit. Despite the outbreak of COVID-19 pandemic, processing of transactions and system operation remained smooth</li>
                    <li>Processing of 34 million business transactions of the public administration per year, the monthly record reaching up to 3.8 million processed business transactions</li>
                    <li>The number of information systems integrated on CPPA has reached 350 and continues to rise</li>
                  </ul>
                  <div className={projectsStyle.projectSpContainer}>
                    <div className={projectsStyle.projectBblSpItem}></div>
                  </div>
                </div>
                <div>
                  <div className={projectsStyle.descrtptionHeading}>
                    <span>2019</span>
                  </div>
                  <ul>
                    <li>Deployment of a technological optimization and an CPPA extension for a production performance boost</li>
                    <li>Processing of 2.8 million business transactions of the public administration per month, almost 30 million a year</li>
                  </ul>
                  <div className={projectsStyle.projectSpContainer}>
                    <div className={projectsStyle.projectBblSpItem}></div>
                  </div>
                </div>
                <div>
                  <div className={projectsStyle.descrtptionHeading}>
                    <span>2018</span>
                  </div>
                  <ul>
                    <li>Implementing the second set of changes of CPPA based on an amended legislation</li>
                    <li>Transmission of the module to Central bureau service, providing an unification of electronical and paper process for delivering decisions</li>
                    <li>Beginning of work on the new CPPA module – Module of bureau communication (API GW)</li>
                    <li>Exceeding the limit of 2 million processed business transactions a month</li>
                  </ul>
                  <div className={projectsStyle.projectSpContainer}>
                    <div className={projectsStyle.projectBblSpItem}></div>
                  </div>
                </div>
                <div>
                  <div className={projectsStyle.descrtptionHeading}>
                    <span>2017</span>
                  </div>
                  <ul>
                    <li>Implementing the first set of changes based on amended legislation</li>
                    <li>Exceeding the limit of 10 million processed business transactions</li>
                  </ul>
                  <div className={projectsStyle.projectSpContainer}>
                    <div className={projectsStyle.projectBblSpItem}></div>
                  </div>
                </div>
                <div>
                  <div className={projectsStyle.descrtptionHeading}>
                    <span>2015/16</span>
                  </div>
                  <ul>
                    <li>Implementation of the work to secure an integration of third-party subjects within CPPA so they can use its functions and services properly according to agreed scenarios and integration manuals</li>
                  </ul>
                  <div className={projectsStyle.projectSpContainer}>
                    <div className={projectsStyle.projectBblSpItem}></div>
                  </div>
                </div>
                <div>
                  <div className={projectsStyle.descrtptionHeading}>
                    <span>2014</span>
                  </div>
                  <ul>
                    <li>Final realization of Central portal of public administration (CPPA) project including the completion of final individual modules, testing, trial operation and introduction into a proper operation</li>
                    <li>Processing of a required analysis and architecture blueprints for the project of Electronic service of the Government Office of Slovak Republic – eDemocracy and an opened government (IIS eDov)</li>
                    <li>Launch of the information system project for payments and the registration of administrative and court fees (IS PEP) as a part of complex service E-stamp into production operation</li>
                  </ul>
                  <div className={projectsStyle.projectSpContainer}>
                    <div className={projectsStyle.projectBblSpItem}></div>
                  </div>
                </div>
              </div>
            </div>
            <div className={projectsStyle.projectImageContainer}>
              <div className={projectsStyle.projectImageItem}>
                <StaticImage src="../../images/dataGovSk.png" alt="data.gov.sk" title="data.gov.sk"/>
              </div>
              <div className={projectsStyle.projectImageItem}>
                <StaticImage src="../../images/slovenskoSk.png" alt="slovensko.sk" title="slovensko.sk"/>
              </div>
            </div>
          </div>
        </div>

    <SiteFooter/>
    </main>
  )
}

export default Project